import RedirectToView from '~/assets/javascript/modules/member/actions/redirect-to-view';

export const userNotificationChannelMixin = {
  channels: {
    UserNotificationChannel: {
      received(data) {
        const { action, params } = data;

        switch (action) {
          case 'notify':
            this.$notifier.showMessage({
              content: params.message,
              color: params.status,
            });

            break;

          case 'redirect_to_page':
            this.redirectToPage(params);

            break;

          case 'notify_tenant_creation':
            this.$store.dispatch('workspace/loadWorkspace');
            this.$auth.fetchUser();

            break;

          default:
            break;
        }
      },
    },
  },
  mounted() {
    if (!this.$auth.loggedIn) return;

    this.$zazosCable.subscribe({ channel: 'UserNotificationChannel' });
  },
  methods: {
    redirectToPage(params) {
      const workflowExecution = this.$store.getters['workflowExecutions/findWorkflowExecutionByRequestId'](params.workflow_execution_request_id);

      // If the workflow execution is not found, it means that this user session is not related to the workflow execution
      // or the user has already navigated to another page and the redirect should not be executed
      if (!workflowExecution) return;
      const currentPath = this.$router.currentRoute.value.fullPath;

      // If the current path is different from the path stored in the workflow execution,
      // it means that the user has navigated to another page and the redirect should not be executed
      if (!_isEqual(currentPath, workflowExecution.path)) {
        this.$store.commit('workflowExecutions/remove', params.workflow_execution_request_id);
        return;
      }

      const action = new RedirectToView(this);

      action.execute({
        viewId: params.view_id,
        recordId: params.view_state === 'new' ? params.source_record_id : params.record_id,
        area: params.view_public ? 'public' : 'member',
        state: params.view_state,
        add_source_record_reference: params.view_state === 'new' && params.source_record_id,
        onBeforeRouteChange: (path) => {
          this.$store.commit('workflowExecutions/updateWorkflowExecutionPath', { requestId: params.workflow_execution_request_id, path });
        },
      });
    },
  },
};
